.lavande {
    height: 120px;
    width: 100%;
    object-fit: cover;
}

.menu-lavande {
    display: flex;
    margin-top: -200px;
    width: 100%;
}

.menu-label {
    color: white;
    flex-wrap: wrap;
    margin-left: 50px;
    cursor: pointer;
    font-size: 18px;
}

.menu-label:hover {
    text-underline-offset: 10px;
    text-decoration: underline;
}

.top-image{
    width: 45%;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.header{
    width: 100%;
}
