.title-30{
    
    font-size: 3rem;
    margin-bottom: 20px;
    margin-top: 10px;
    color:#db5965;
}
.card{
    margin-top: 50px;
}
.dessin{
    margin-left: 10PX;
    margin-right: 10PX;
}
.dessin-30{
    margin-top: -54px;
}
.annive{
    margin: 40px;
    background-color: rgba(219, 89, 101, 0.2);
}
.rowJ{
    display: flex;
    margin-top: 20px;
}
.jour{
    width: 166PX;
    height: 15%;
    background-color: #db5965;
    color: white;
    opacity: 0.6;
    font-size: 3rem;
   
    
}
.jourj{
justify-content: center;
margin-top: 20px;
margin-bottom: 20px;
display: flex;
}
.title-20{
    margin-top: 20px;
    font-size: 1.5rem;
    margin-bottom: 20px;
    color:#db5965;
}
.rowrow{
    display: flex;
    flex-wrap: wrap;
    margin-top: 25PX;
}
.container2{
    display: flex;
    flex-wrap: wrap;
}
.colcol{
    flex: 1 0 0%;
}