.menu-label:hover {
    text-underline-offset: 10px;
    text-decoration: underline;
}

.top-image {
    width: 49%;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.bar-menu {
    margin-top: -77px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.container {
    position: relative;
    flex-wrap: wrap;
    margin-right: 3.5rem;
    margin-left: 3.5rem;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 58px;

  }

.p-menubar {
    border: none;

    .p-menuitem {
        flex: 1 1 auto;
    }

    ul {
        flex: 1;

        a {
            justify-content: center;
            font-size: 19px;

            &:focus {
                box-shadow: none !important;
                background: none !important;

                span {
                    color: #9370DB !important;
                }
            }

            &:hover {
                span {
                    color: #9370DB !important;
                }

                box-shadow: none !important;
                background: none !important;
            }
        }

        a:after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 0%;
            content: '.';
            color: transparent;
            background: #9370DB;
            height: 2px;
        }
    }
}

ul li a:hover:after {
    width: 90%;
}

ul li a,
ul li a:after,
ul li a:before {
    transition: all .5s;
}